import { EyeIcon } from "@heroicons/react/24/outline";
import { FC }      from "react";

type InstrumentResultsTableProps = {
  setSelectedPipeline: any,
  pipelines: any[] | undefined
}

const PipelineResultsTable: FC<InstrumentResultsTableProps> = ( {
  setSelectedPipeline,
  pipelines,
} ) => {
  console.log( pipelines );
  
  // Sort pipelines: latest non-null timestamps first, null timestamps last
  const sortedPipelines = pipelines?.sort( ( a, b ) => {
    // Check if either timestamp is "N/A" or null
    if ( a.timestamp === "N/A" || a.timestamp === null ) {
      return 1;
    }
    if ( b.timestamp === "N/A" || b.timestamp === null ) {
      return -1;
    }
    
    // If both are valid timestamps, sort in descending order
    return new Date( b.timestamp ).getTime() - new Date( a.timestamp ).getTime();
  } );
  
  return !( sortedPipelines && sortedPipelines.length ) ? <></> : (
    <div>
      <span className="text-base font-semibold leading-6 text-secondary">Pipelines</span>
      <div className="mx-auto max-w-7xl">
        <div className="bg-primary py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-primaryShadow">
                    <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Pipeline ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Pipeline Type
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Full Pipeline
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Core Included
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Start Time
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Select</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-primaryShadow">
                    {sortedPipelines!.map( ( pipeline: any ) => (
                      <tr key={pipeline.pipeline_id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{pipeline.pipeline_id}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{pipeline.pipeline_type}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{pipeline.pipeline_full ? "Full" : "Partial"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{pipeline.pipeline_include_core ? "With Core" : "Without Core"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">
                          {pipeline.timestamp && pipeline.timestamp !== "N/A" ? pipeline.timestamp : "N/A"}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <EyeIcon className="h-6 w-6 text-tertiary hover:text-tertiaryShadow hover:cursor-pointer" aria-hidden="true"
                                   onClick={() => setSelectedPipeline( pipeline )}/>
                        </td>
                      </tr>
                    ) )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineResultsTable;