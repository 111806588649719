import { useState }                                                from "react";
import { useMutation }                                             from "react-query";
import {
  useNavigate,
}                                                                  from "react-router-dom";
import { AdminRequests, DefaultPage, getBasicAuth, submitRequest } from "../utils/routes";

const LoginPage = () => {
  const navigate = useNavigate() as any;
  
  const [ email, setEmail ]       = useState( "" );
  const [ password, setPassword ] = useState( "" );
  
  const loginMutation = useMutation( {
    mutationFn: submitRequest,
    onSuccess: ( data: any ): void => {
      console.log( "Login successful" );
      localStorage.setItem( "password", password );
      localStorage.setItem( "email", email );
      localStorage.setItem( "user", data.user );
      
      navigate( DefaultPage );
    },
  } );
  
  const login = (): void => {
    loginMutation.mutate( {
      queryKey: [
        AdminRequests.AuthLogin,
        undefined,
        undefined,
        getBasicAuth( email, password ),
      ],
    } );
  };
  
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="/logo512.png"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-secondary">
            Alpen Admin Sign-In
          </h2>
        </div>
        
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {/*<form className="space-y-6" action="#" method="POST">*/}
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-secondary">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={( e: any ) => setEmail( e.target.value )}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-secondary shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-tertiary sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-secondary">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={( e: any ) => setPassword( e.target.value )}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-secondary shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-tertiary sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div>
              <button
                className="flex w-full justify-center rounded-md bg-tertiary px-3 py-1.5 text-sm font-semibold leading-6 text-secondary shadow-sm hover:bg-tertiary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tertiary"
                onClick={login}
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginPage;