import { COORDINATOR_PORT, HOST } from "./consts";

// // Backend routes
export enum Method {
  Get    = "GET",
  Post   = "POST",
  Put    = "PUT",
  Patch  = "PATCH",
  Delete = "DELETE",
}

// const adminUrl: string = `http://localhost:58934/api/v1/alpen/admin`;
const adminUrl: string = `https://api.alpen.finance:58934/api/v1/alpen/admin`;

export const AdminRequests: any = {
  
  // Auth
  AuthLogin: { route: `${adminUrl}/auth/login`, method: Method.Patch },
  
  // Users
  GetAllUsers: { route: `${adminUrl}/users/all`, method: Method.Get },
  GetUser: { route: `${adminUrl}/users`, method: Method.Get },
  CreateUser: { route: `${adminUrl}/users`, method: Method.Post },
  UpdateUser: { route: `${adminUrl}/users`, method: Method.Put },
  DeleteUser: { route: `${adminUrl}/users`, method: Method.Delete },
  
  // Pipelines
  RunPipeline: { route: `${adminUrl}/pipelines/run`, method: Method.Patch },
  PurgeQueues: { route: `${adminUrl}/queues/purge`, method: Method.Patch },
  GetAllPipelines: { route: `${adminUrl}/pipelines/all`, method: Method.Get },
  GetPipelineTasks: { route: `${adminUrl}/pipelines/tasks`, method: Method.Get },
  GetTaskSubtasks: { route: `${adminUrl}/pipelines/tasks/subtasks`, method: Method.Get },
  
  // Instruments
  MetadataSearch: { route: `${adminUrl}/metadata/search`, method: Method.Get },
  
  // Digests
  GetDigests: { route: `${adminUrl}/digests/unsent`, method: Method.Get },
  SendDigest: { route: `${adminUrl}/digests/send`, method: Method.Patch },
  
  // Dashboard
  GetDashboardScalars: { route: `${adminUrl}/dashboard/scalars`, method: Method.Get },
  
  // Calcs
  GetAllCalcs: { route: `${adminUrl}/calcs/all`, method: Method.Get },
  
};

export const getBasicAuth = ( email: string, password: string ): string => {
  return `Basic ${btoa( `${email}:${password}` )}`;
};

export const submitRequest = async ( props: any ): Promise<any | any[]> => {
  try {
    const [ request, params, body, auth ] = props.queryKey;
    
    const queryString = new URLSearchParams( params ).toString();
    const url         = !!queryString ? `${request.route}?${queryString}` : request.route;
    
    const response = await fetch( url, {
      method: request.method,
      headers: {
        Authorization: auth ?? undefined,
        "Content-Type": "application/json",
      },
      body: request.method === "GET" ? undefined : (
        typeof body === "undefined" || typeof body === "function" || typeof body === "symbol"
          ? undefined : JSON.stringify( body )
      ),
    } );
    console.log( "response", response );
    if ( !response.ok ) {
      throw new Error( `HTTP error! status: ${response.status}` );
    }
    const responseJson = await response.json();
    console.log( "responseJson", responseJson );
    return responseJson;
  } catch ( error ) {
    console.error( "Error fetching instruments:", error );
    return [];
  }
};

// Coordinator

export const coordinatorDomain: string = `http://${HOST}:${COORDINATOR_PORT}`;
export const coordinatorPath: string   = "/coordinator/api/v1";

export enum CoordinatorRoutes {
}

export const getCoordinatorUrl = (
  path: CoordinatorRoutes, params?: { [key: string]: string } ): string => {
  let concretePath: string = path.toString();
  if ( !!params && Object.keys( params ).length > 0 ) {
    for ( let key in params ) {
      concretePath = concretePath.replace( `:${key}`, params[key] );
    }
  }
  return `${coordinatorDomain}${coordinatorPath}${concretePath}`;
};

// // Frontend routes

export enum AppRoutes {
  Dashboard      = "/dashboard",
  Institutions   = "/institutions",
  Factors        = "/factors",
  Metadata       = "/metadata",
  Calcs          = "/calcs",
  InstrumentView = "/instrument/view",
  Pipelines      = "/pipelines",
  Digests        = "/digests",
  Users          = "/users",
  UserView       = "/user/view",
  UserEdit       = "/user/edit",
  UserNew        = "/user/new",
  
  Login          = "/",
  
  Error          = "/error",
}

export const DefaultPage: string = AppRoutes.Users;